@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,500;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500;600;700;800&family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap');

.privacy {
    width: 100%;
    height: auto;
    min-height: 100vh;
}

.policy {
    width: 80%;
    margin: 1rem auto;
    font-family: 'League Spartan', sans-serif;
    padding: 0.5rem 0;

}

.policy h1 {
    text-align: center;
}

.policy>h1,
h2 {
    font-family: 'League Spartan', sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    text-decoration: none;
    text-align: center;
    color: #004FF9;
    margin: 1rem auto;

}

.policy>h2 {
    font-size: 2.2rem;
}

.policy>p {
    font-size: 20px;
}