.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Image {
    float: right;
    transform: translateY(0%);
    transform: translateX(80%);
    position: absolute;
    z-index: -1;
}

.Imgcontainer {
    width: 100%;
    height: 100%;

}

.Das {
    float: left;
    transform: translateY(50%);
    position: absolute;
    z-index: -1;
}

.Dash {
    float: left;
    transform: translateY(70%);
    position: absolute;
    z-index: -1;
}

.Container3 {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.Container2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Container2>p {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    margin-top: 3rem;
}


.Container2>p:nth-child(2) {
    font-size: 2.5rem;
    font-weight: 700;
    color: #004FF9;
}

.ProfileImage {
    width: 12rem;
    height: 12rem;
    border-radius: 50%;
}