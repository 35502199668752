.Text {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 2rem;
    color: #fff;
    margin: 0;
    padding: 0;
    text-align: justify;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0;
    word-spacing: 0;
    text-indent: 0;
    text-shadow: none;
    white-space: normal;
    word-wrap: normal;
    word-break: normal;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
}

@media screen and (max-width: 768px) {
    .Text {
        text-align: center;
    }
}
 

@media screen and (max-width: 468px) {
    .Text {
        font-size: .75rem;
}
}