.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Container2>p {
    font-size: 2.5rem;
    font-weight: 700;
    color: #004FF9;
    text-align: center;
}

.Container3{
    width: 85%;
    height: 50vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 32px;
    background: #cccccc6d;
    padding: 1rem;
    margin:2rem;
}

.Box{
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: .5rem;
}

.Box>p:nth-child(2){
    text-align: justify;
    font-size: 1rem;
}

.Container4{
    display: flex;
    flex-direction: column;
    background: #ffffff99;
    border-radius: 16px;
    width: 90%;
    height: 100%;
}

.InputBox{
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .Container3{
        height: 100%;
        width: 90%;

    }
}
@media screen and (max-width: 468px) {
    .Container3 {
        height: 100%;
        width: 90%;
        flex-direction: column;
    }
    .InputBox{
        flex-direction: column;
    }
}