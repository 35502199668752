.Footer{
    height: 21vh;
    padding: 0 50px;
    background-color: #F4F5FA;
    /* position: fixed;
    bottom: 0; */
}
.Footer .FooterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}

.Footer .FooterContainer .Right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.Footer .LineBreak{
    width: 100%;
    height: 2px;
    background-color: red;
}

@media screen and (max-width: 468px) {
        .Footer {
            height: 100%;
            padding: 0 25px;
            background-color: #F4F5FA;
        }
        .Footer .FooterContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

        }
        .Footer .FooterContainer .Right {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
        }
}