.Container{
    background-color: #e2e20b;
    border-radius: 16px;
    padding: 3rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-weight: 700;
    font-size: 2rem;
    color:'#fff';
    margin-top: 3rem;
    margin-bottom: 2rem;
}
.Link{
    cursor: pointer;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    padding: .5rem;
    text-decoration: none;
    margin-left: 1rem;
    margin-top: 2rem;
}