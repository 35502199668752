.Container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.TopContainer{
    width: 100%;
    height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: #000;
}

.TopContainer>p{
    color: #fff;
    font-weight: 700;
    font-size: 2rem;
}

.Content1 {
    font-size: 2.5rem;
    font-weight: 700;
    color: #004FF9;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

/* Add margin to the table */
section.sectionTable {
    margin-bottom: 15%;
    /* Adjust the value as per your desired space */
}

.sectionTable {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* p{
    font-weight: 600;
    color: #333;
} */

.TopContainer>p:nth-child(2){
    font-size: 1.2rem;
    color: #fff;

}

.BottomContainer{
    margin-top: 1rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Flex{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

@media screen and (max-width: 468px) {
    .sectionTable {
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
        }
}