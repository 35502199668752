.Button {
    background-color: #004FF9;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin: 20px 0px;
    padding: 12px 36px;
    text-align: center;
    text-decoration: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

.Button:hover {
    background-color: #e7e7e7;
    border-color: #d5d5d5;
    color: #004FF9;
}

.Button:active {
    background-color: #e7e7e7;
    border-color: #d5d5d5;
    color: #333;
}

