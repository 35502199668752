.Search {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin: 50px 0px;
}

.Search input {
    width: 80%;
    height: 50px;
    border-radius: 20px;
    padding: 0 20px;
    font-size: 16px;
    outline: none;
    border: 0px solid #ccc;
}


.Search button {
    height: 40px;
    border: none;
    border-radius: 20px;
    background-color: #004FF9;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    outline: none;
    padding: 0 20px;
    margin: 0 10px;
}

.Search .SearchButton:hover {
    background-color: #333;
}
