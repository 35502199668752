.AboutContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #004EF8;
    padding: 1rem;
}

.AboutContainer .InnerContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.AboutContainer .Left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.AboutContainer .Right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.AboutContainer .Right .Image {
    width: 90%;
    height: 90%;
    object-fit: cover;
    object-position: center;
}
.AboutContainer .Right .Image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}   
 .Header {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .AboutContainer {
        padding: 0px;
    }
    
    .AboutContainer .InnerContainer{
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .AboutContainer .Left{
        width: 100%;
        height: 100%;
    }
    .AboutContainer .Right {
        width: 100%;
        height: 100%;
    }
    .Header {
        width: 90%;
        align-items: center;
        margin-top: 20px;
    }
    


}