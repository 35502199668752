.Container{
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
}

.Image{
    float: right;
    transform: translateX(90%);
    position: absolute;
    z-index: 1;
}
.Content{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.Content>p {
    color:#004FF9;
    font-size: 3rem;
    font-weight: 700;
    margin-top: 5rem;
}

.Container2{
    width: 100%;
    height: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  
}

.Container2>div{
      width: 100%;
      height: 30%;
      display: flex;
    flex-direction: row;
    margin: auto;    
    padding: 1rem;
        align-items: center;
    justify-content: center;
}
.Link{
    background: #004FF9;
    color: #fff;
    margin: .5rem;
    padding: 1rem;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 10;
}

.Link:hover{
    cursor: pointer;
     background: #fff;
    color: #004FF9;
    border: 1px solid #004FF9;
}
@media screen and (max-width: 1024px) {
    .Image{
        display: none;
    }
}

@media screen and (max-width: 468px) {
    .Container{
        height: 80vh;
    }
    .Image{
        display: none;
    }
    .Content>p{
        font-size: 1.5rem;
    }
        .Container2 {
            flex-direction: column;
            margin-top: 2rem;
        }

.Link {
    background: #004FF9;
    color: #fff;
    margin: .75rem;
    padding: .5rem;
    text-transform: uppercase;
    text-decoration: none;
    z-index: 0;
}
}