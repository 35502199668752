.JobCard{
    width: 300px;
    min-height: 270px;
    box-shadow:   0 0 5px 0 rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem;
}
 /* .JobCard>div{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
 } */

 .CompanyName{
    font-weight: 600;
 }

 .JobCard>div:nth-child(2){
    display: flex;
    flex-direction: column;
 }

 .JobCard>div:nth-child(2)>div{
    display: flex;
    width: 100%;
    justify-content: space-between;
 }
 .JobTitle{
   color: #004FF9;
   background: #004ff92e;
   padding: .5rem;
   border-radius: 16px;
 }

 .JobCard>div:nth-child(2)>div>a{
    background: #004FF9;
    border:1px solid #004FF9;
    color:#fff ;
    padding: 1rem .5rem;
    border-radius: 4px;
    text-decoration: none;
 }

 .JobCard>div:nth-child(2)>div>a:hover {
     background: #fff;
     border: 1px solid #004FF9;
     color: #004FF9;
     cursor: pointer;
 }

 .JobTitle{
    font-size:1.25rem ;
    font-weight: 700;
    text-align: left;
 }

 .JobContent{
    font-size: 1rem;
    text-align: left;
 }

 .Price{
    font-size: 1.1rem;
 }

 .Link{
   display: block;
   padding: .5rem;
   color: #fff;
   background-color: #004FF9;
   text-decoration: none;
   border-radius: 16px;
   margin-top: 1rem;
   cursor: pointer;
 }

 @media screen and (max-width: 768px){
  .JobContent{
    font-size: 1rem;
    text-align: center;
 }
 }

 .ViewJob{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
 }
 .ViewJob p{
   padding: 0.2rem 1rem;
   color: #fff;
 }

