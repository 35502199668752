.Container{
    display: flex;
    flex-direction: column;
    align-items:'center';
    justify-content: center;
    width: 100%;
    height: 80vh;
}

.Container>p{
    color: #fff;
    font-size: 4rem;
    font-weight: 700;
    text-shadow: 0 0 4px #004FF9, 0 0 8px #0000ff;
    margin-bottom: 1rem;
}

.Container>img{
    width: 100%;
    height: 30%;
}