@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,500;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,100;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&family=Work+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@300;400;500;600;700;800&family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&display=swap');

:root {
    --blue: #004ff9;
}

main {
    width: 100%;
}

.contactSect {
    display: flex;
    flex-direction: column;
    min-height: 80vh;
    height: auto;
    width: 100%;
    /* outline: 5px solid red; */


}


.contactUs {
    display: flex;
    /* outline: 1px solid red; */
    height: 100%;
    min-height: 50vh;
    margin: 0;

}

.contactOne {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    color: #fff;
    background-color: var(--blue);
    width: 40%;
    height: auto;
    min-height: 90%;


}

.contactOne>p,
h3 {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    color: #fff;
}

.contactOneInner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    margin: 0 auto;


}

.contactOneInner>h3 {
    text-align: center;
}

.contactTwo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 60%;
    height: 90%;

}

.info {
    width: 100%;
    margin: 0;
    height: 11%;
    padding: 0.75rem 0 0.25rem 0;
    /* outline: 1px solid red; */
}

.info>h1 {
    text-align: center;
}

.info>p {
    text-align: center;
}


.contactTwoInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 0 auto;
    padding: 0.5rem;
    /* outline: 1px solid red; */
}

.contactTwoInner>button {
    font-size: 1.6rem;
    border: none;
    border-radius: none;
    color: #fff;
    padding: 0.25 0.5rem;
    margin: 0 0.25rem;
    background-color: transparent;
    width: 80px;
    cursor: pointer;
}

.contactTwoInner>button:hover {
    background-color: #fff;
    color: #004ff9;
}

/* CONTACT FORM SECTION */
.formDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    /* outline: 1px solid red; */
    width: 100%;
    height: 89%;
    margin: 0;
}

.contactForm {
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction:column;
    align-items: baseline;
    /* outline: 1px solid red; */
}

.contactForm>label {
    font-family: 'League Spartan', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;


}

.contactForm>input,
textarea {
    display: block;
    width: 80%;
    height: 30px;
    padding: 0.25rem 0.5rem;
    font-family: 'League Spartan', sans-serif;
    font-style: normal;
    font-size: .9rem;
    margin: 0.25rem 0;
    background-color: #f2f4f5;

}

.contactForm>textarea {
    font-family: 'League Spartan', sans-serif;
    font-style: normal;
    font-size: .9rem;
    padding: 0.75rem 0.5rem;
    height: 70px;
    background-color: #f2f4f5;
}

.contactForm>button {
    font-family: 'League Spartan', sans-serif;
    font-style: normal;
    font-size: 1rem;
    border: none;
    border-radius: none;
    height: 45px;
    width: 300px;
    margin: 1rem 0;
    color: #fff;
    background-color: var(--blue);
    cursor: pointer;
}

.contactForm>button:hover {
    background-color: #1145b6;
}

@media (max-width: 900px) {
    .contactSect {
        width: 100vw;
        height: auto;
        min-height: 100vh;
        margin: 0;

    }

    .contactUs {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        width: 100%;
        min-width: 100vw;
        margin: 0;

    }

    .contactOne {
        align-items: flex-start;
        background-color: var(--blue);
        width: 100%;
        height: auto;
        min-height: 50vh;
        margin: 0;
    }

    .contactOne>p,
    h3 {
        text-align: center;
        width: 80%;
        margin: 0 auto;
    }

    .contactOneInner>p {
        font-family: 'League Spartan', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        width: 100%;
        color: #fff;
        /* margin: 0; */
        /* outline: 1px solid red; */
    }

    .contactTwo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;

    }

    .contactForm>input,
    textarea {
        width: 280px;
    }

    .contactTwoInner {
        width: 70%;
        /* outline: 1px solid red; */
    }
}