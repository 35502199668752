.Container {
    width: 100%;
     min-height:80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.NotificationContainer{
    display: flex; 
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 1rem;
    box-shadow: '5px 5px 5px #000';
   
}



.NotificationContent{
    display: flex; 
    width: 70%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.NotificationContent>p{
    color: #1D4ED8;
    font-size: 14px;
    font-weight: 700;
}

.NotificationContent>p:nth-child(2){
    font-size: 14px;
        font-weight: 700;
        color: #1e2020 ;
        margin-top: 1rem;
        margin-bottom: 1rem;
}

.NotificationContent>p:nth-child(3){
    font-size: 14px;
        font-weight: 700;
        color: #1e2020;
}