.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Image {
    float: right;
    transform: translateY(0%);
    transform: translateX(80%);
    position: absolute;
    z-index: -1;
}

.Imgcontainer {
    width: 100%;
    height: 100%;

}

.Das {
    float: left;
    transform: translateY(50%);
    position: absolute;
    z-index: -1;
}

.Dash {
    float: left;
    transform: translateY(70%);
    position: absolute;
    z-index: -1;
}

.Container3 {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;
}

.Container2>p {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    margin-top: 3rem;
}

.Container2>p:nth-child(2) {
    font-size: 2.5rem;
    font-weight: 700;
    color: #004FF9;
}

.ProfileImage {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    object-fit: contain;
}

/* individual dashboard */
.Welcome{
    font-size: 1.5rem;
    font-weight: 700;
    color:#000;
}

.Name {
    font-size: 2rem;
    font-weight: 700;
    color: #004FF9;
    margin-top: -1rem;
    margin-bottom: -.5rem;
}

.ProfileContainer1{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.ProfileContainer{
    width:75%;
    height:100%;
    display:'flex';
    flex-direction: column;
    justify-content: center;
    gap: 6;
    background: #f2f2f2;
    padding: 1rem;
    border-radius: 16px;
    margin: auto;
    align-items: center;
    margin-bottom: 2rem;
}

.ProfileContent{
    color: #004FF9 ;
    font-size: 1rem;
    text-align: justify;
}
.Container2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


@media screen and (max-width: 768px) {
/* .ProfileContainer p{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
} */
   .ProfileContainer1 {
    padding: 1rem;
   }
   .ProfileImage {
       width: 8rem;
       height: 8rem;
       border-radius: 50%;
       object-fit: contain;
   }
}

@media screen and (max-width: 468px) {
    .ProfileContainer p {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: center;
    }
}

