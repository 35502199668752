.Jobs{
    padding: 0px 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height:80vh

}
.Jobs .Design1{
    position: absolute;
    top: 0px;
    right: 0px;
}

@media screen and (max-width: 768px){
    .Jobs{
        width: 100%;
        padding: 0px;
        justify-content: center;
    }

}

