.JobsContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    z-index: 1;
}

@media screen and (max-width: 768px){
    .JobsContainer{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}