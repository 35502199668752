.AuthenticationButton {
     width: 60%;
    height: 50px;
  background-color: #004FF9;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  font-size: 1.2rem;
}

.AuthenticationButton:hover {
  background-color: #e5e5e5;
  border-color: #d5d5d5;
  color: #000000;
}