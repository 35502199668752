.NavText {
    font-size: 1.2rem;
    font-weight: 500;
    text-decoration: none;
    padding: 0px 16px;
    border-radius: 15px;
}

.NavText:hover {
    color: #004FF9;
    cursor: pointer;
    background-color: #E5E5E5;

}
.NavText:active {
    color: #004FF9;
    background-color: #E5E5E5;
    cursor: pointer;
}

.NavText p{
    padding: 15px 10px;
}

@media screen and (max-width: 468px) {
    .NavText{
        font-size: .7rem;
        padding: 0px 2px;
    }

    .NavText p {
        padding: 4px 5px;
    }
}