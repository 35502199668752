.Login{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
}

.Login .Left{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.Login .Right{
    width: 40%;
    height: 60vh;
    margin: 30px;
    box-shadow:   0 0 5px 0 rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.Login .Left .Pole{
    width: 40px;
    height: 50vh;
    background-color: #000;
}
.Login .Left .BarnerRight{
    width: 200px;
    height: 50px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: #1D4ED8;
    position: absolute;
    top: 50px;
    left: 40%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
}
.Login .Left .BarnerLeft{
    width: 200px;
    height: 50px;
     border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    background-color: #1D4ED8;
    position: absolute;
    top: 150px;
    left: 20%
}

@media screen and (max-width: 768px) {
    .Login {
        flex-direction: column;
        padding: 0px 8px;
    }

    .Login .Left {
        width: 100%;
        display: none;
    }

    .Login .Right {
        width: 100%;
        height: 100%;
        margin: 10px;
        padding: 2rem;
        justify-content: space-evenly;
        gap: 4;
    }
}

