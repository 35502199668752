.HowItWorks{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: #fff;
    padding: 0 50px;
}

.HowItWorks .InnerContainer{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.HowItWorks .Left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.HowItWorks .Right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.HowItWorks .Right .Image {
    width: 90%;
    height: 90%;
    object-fit: cover;
    object-position: center;
}
.HowItWorks .Right .Image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}   
 .Header {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .HowItWorks {
            padding: 0 2px;
            height: 100%;
        }
    .HowItWorks .InnerContainer{
        flex-direction: column-reverse;
        align-items: flex-start;
    }
    .HowItWorks .Left{
        width: 100%;
        height: 100%;
    }
    .HowItWorks .Right {
        width: 100%;
        height: 100%;
    }


}