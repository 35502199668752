
.Logo img {
    height: 100px;
    object-fit: contain;
    object-position: center;
}
@media screen and (max-width: 468px) {
    .Logo img{
        height: 40px;
    }
}