.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.OrgainizationContainer{
    width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;
}

.OrganizationContainer2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.OrgainizationContainer2>p {
    font-size: 1.5rem;
    font-weight: 700;
    color: #000;
    margin-top: 3rem;
}

.OrgainizationContainer2>p:nth-child(2) {
    font-size: 2.5rem;
    font-weight: 700;
    color: #004FF9;
}

.Image {
    float: right;
    transform: translate(42%,-55%);
    position: absolute;
    z-index: 9;
}
.Imgcontainer{
    width: 100%;
    height: 100%;
    
}
.Das{
    float: left;
    transform: translateY(50%);
    position: absolute;
     z-index: -1;
}

.Dash {
    float: left;
    transform: translateY(70%);
    position: absolute;
    z-index: -1;
}
.Container3 {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}
.Container2>p{
    font-size: 1.5rem ;
    font-weight: 700 ;
    color: #000 ;
    margin-top: 3rem;
}
.Container2>p:nth-child(2){
    font-size: 2.5rem;
    font-weight: 700;
    color: #004FF9;
}

.headerContainer>p {
    font-family: "League Spartan", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
    text-decoration: none;
    color: #004ff9;
    margin: 2rem auto;
  }

  h2 {
    font-family: "League Spartan", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
    text-decoration: none;
  }

/* Add margin to the table */
section.sectionTable {
    margin-bottom: 15%; /* Adjust the value as per your desired space */
  }

table {
    width: 90%;
    margin: 1rem 0;
    border: 1px solid #000;
    border-collapse: collapse;
    margin: 0px;
}

table td, th, thead {
    padding: 0.5rem;
    text-align: left;
    border: 1px solid #000;
    white-space: nowrap;
  }
  
  table > thead:first-of-type {
    background-color: #E5E5E5;
  }

.sectionTable {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

.searchDiv {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding : 50px 0px;
  }
  
  .searchDiv .search{
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .searchDiv .search input{
    width: 40%;
    height: 100%;
    outline: none;
    border: none;
    padding: 10px;
    font-size: 20px;
    padding-left: 50px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
  }
  .searchDiv .search .searchButton{
    width: 20%;
    outline: none;
    border: none;
    background-color: #407BFF;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    font-family: "Leaguespartan", sans-serif;
    border-radius: 0px 10px 10px 0px;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    padding: .65rem;
  }
  
  .searchDiv .search .searchButton:hover{
    background-color: #004FF9;
  }

  .jobmsg {
    font-family: "League Spartan", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
  }

  .jobsdiv {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 1rem;
    width: 90%;
    margin: auto;
    /* outline: 1px solid red; */
  }

.button{
    border: none;
    border-radius: 10px;
    background-color: #F0EEEE;
    color: #407BFF;
    font-size: 1.1rem;
    font-weight: 500;
    font-family: "Leaguespartan", sans-serif;
    padding: 0.8rem 2rem;
    border: 1px solid #407BFF;
    text-decoration: none;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    margin: auto;
}

.button:hover {
    background-color: #004ff9;
    color: #fff;
  }



.ProfileImage{
    width: 12rem ;
    height: 12rem ;
    border-radius: 50%;
    display: block;
    margin: auto;
}

/* organization dashboard */



.Content1{
    font-size: 2.5rem;
    font-weight: 700;
    color: #004FF9;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.Container>div{
    width: 90%;
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
}

/* application */
.ApplicationContainer {
    width: 90%;
    margin: auto;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;

}

.Contentapp {
    font-size: 2.5rem;
    font-weight: 700;
    color: #004FF9;
}

.Content{
    background: #407cff43;
    color: #000000dc;
    border-radius: 20px;
    padding: 1rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    
}

.Content2 {
    background: #407cff43;
    color: #000000dc;
    border-radius: 20px;
    padding: 1rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border: none;
}

.Content3 {
    background: #407cff43;
    color: #000000dc;
    border-radius: 20px;
    padding: 1rem;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border: none;
    min-height: 30vh;
    text-align: justify;
    line-height: 2rem;

}

.Container4{
    width: 90%;
    display: flex;
    align-items: flex-start;
}

.footer {
    position: relative;
    bottom: 0;
    max-width: 100%;
    /* margin: 1rem 0 0.5rem 0; */
    background-color: #f2f4f5;
    margin: 0;
}

.disclaimer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20vh;
    background-color: #444444;
}

.disParadiv {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    height: 80%;
}

.disParadiv .ptag {
    font-family: 'League Spartan', sans-serif;
    font-style: normal;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6rem;
    width: 90%;
    color: #fff;
}



.disImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    height: 80%;
}

.disImg img{
    width: 80%;
    height: 90px;
    object-fit: cover;
}

.footer > small {
    font-family: 'League Spartan', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    margin: auto 0 1rem 4.5rem;
}

.msgDetCont .contentCont{
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 0px;
    margin-left: 7.3%;
}
.contentCont >p{
    padding-left: 10px;
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Leaguespartan", sans-serif;
}
.contentCont .subject{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: "Leaguespartan", sans-serif;
    background-color: #F5F8FF;
    border-radius: 5px;
}
.contentCont .subject p{
    padding-left: 10px;
    margin-top: 10px;
}
.contentCont .content{
    width: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: "Leaguespartan", sans-serif;
    background-color: #F5F8FF;
    border-radius: 5px;
}

.headerContainer{
    justify-content: flex-start; 
    padding-top: 5%;
     padding-left: 5%;
}
.btnCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    height: 60px;
    margin: 1rem 0;
}

.btnCont button {
    border: none;
    border-radius: 14px;
    padding: 7px 16px;
    color: #fff;
}

.reply {
    background-color: #004FF9;
    border: none; border-radius: 14px;
     padding: 10px 21px; color: rgb(255, 255, 255);
     background: rgb(0, 79, 249); text-decoration: none;
     cursor: pointer;
}

.delete {
    background-color: #FF1A2F;
    border: none; border-radius: 14px;
     padding: 10px 21px; color: rgb(255, 255, 255);
     background: rgb(255, 26, 47);
      text-decoration: none;
      cursor: pointer;
}

.delete:hover {
    background-color: #c22a39;
}

.MessageContainer{
    width: 100%;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

@media (max-width: 1024px) {
    .footer {
        display: none;
    }
    .Image{
        display: none;
    }
   
}

@media screen and (max-width: 468px) {
    .Content2 {
            background: #407cff43;
            color: #000000dc;
            border-radius: 20px;
            padding: 1rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            border: none;
        }
        .Content3 {
            background: #407cff43;
            color: #000000dc;
            border-radius: 20px;
            padding: 1rem;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: baseline;
            border: none;
            min-height: 30vh;
            text-align: justify;
            line-height: 2rem;
        }
    .headerContainer>p{
        font-size: 1.5rem;
    }
    .contentCont>p,.contentCont .subject,.contentCont .content{
        font-size: 1rem;
    }
    .contentCont{
        width: 100%;
        padding: 1rem 0;
    }
.searchDiv .search{
    width: 95%;
}
.searchButton{
    display: flex;
    width: 20%;
    font-size: .75rem !important;
    padding: 1rem !important
    ;
}
table td,
th,
thead{
    font-size: .75rem !important;

}
table {
    width: 40% !important;
    margin: auto;
    border: 1px solid #000;
    border-collapse: collapse;
    margin: 0px;
}
.MessageContainer{
    width: 100%;
}
.sectionTable {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
}