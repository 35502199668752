.InputBox {
    width: 60%;
    height: 50px;
    border: 1px solid #264ECA;
    display: flex;
    flex-direction:row ;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px
}


.InputBox .Icon{
    width: 50px;
    height: 100%;
}

.InputBox input, .InputBox>select, .InputBox>textarea{
    width: 80%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: 500;
    color: #264ECA;
    background-color: transparent;
}

.InputBox input::placeholder{
    color: #264ECA;
    font-size: 1rem;
    font-weight: 500;
}

.InputBox input:focus{
    border: none;
    outline: none;
}
.ContainerInput{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ContainerInput>p{
    color: #1D4ED8;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
}

.InputStyles{
    padding: 1rem;
    border: none;
    background: #EBEBEB;
    border-radius: 4px;
    margin-bottom: .5rem;
    width: 90%;
}

.SelectInputStyles {
    padding: 1rem;
    border: none !important;
    border-radius: 4px;
    margin-bottom: .5rem;
    width: 90%;
    background: #EBEBEB;
    text-align: left;
    outline: none !important;
}

.SelectInputStyles:focus{
    border: none !important;
    outline: none !important;
}

@media screen and (max-width: 468px) {
    .InputBox input::placeholder {
            color: #264ECA;
            font-size: .75rem;
            font-weight: 500;
        }
                .InputBox input,
                .InputBox>select,
                .InputBox>textarea {
                    width: 80%;
                    height: 100%;
                    border: none;
                    outline: none;
                    padding: 0 1rem;
                    font-size: .75rem;
                    font-weight: 500;
                    color: #264ECA;
                    background-color: transparent;
                }
}