.SearchInput {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    font-size: 16px;
    color: #333;
    outline: none;
    transition: all 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.SearchInput:focus {
    border-color: #004FF9;
    box-shadow: 0 0 5px 0 rgba(0, 79, 249, 0.3);
    height: 50px;
}

.SearchInput::placeholder {
    color: #ccc;
}

.SearchInput:hover {
    border-color: #004FF9;
    box-shadow: 0 0 5px 0 rgba(0, 79, 249, 0.3);
    height: 60px;
}

.SearchInput input {
    width: 70%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 16px;
    color: #333;
    background-color: transparent;
}
.SearchInput button {
    width: 20%;
    padding: 10px 0px;
    border: none;
    outline: none;
    background-color: #004FF9;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
}
.SearchInput button:hover {
    color: #004FF9;
    background-color: #E5E5E5;
}

@media screen and (max-width: 768px) {
    .SearchInput{
        margin-top: 20px;
    }
}