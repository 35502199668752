.IntroContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    padding: 0px 50px;
    background-color: #fff;
}
.IntroContainer .Left{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.IntroContainer .Right {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.IntroContainer .Right .Image {
    width: 100%;
    height: 70%;
    object-fit: cover;
    object-position: center;
}
.IntroContainer .Right .Image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}   
 .Header {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 768px) {
    .IntroContainer {
        flex-direction: column-reverse;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .IntroContainer .Left{
        width: 100%;
        height: 100%;
    }
    .IntroContainer .Right {
        width: 100%;
        height: 100%;
    }
    .IntroContainer .Right .Image {
        width: 100%;
        height: 100%;
    }
    .IntroContainer .Right .Image img {
        width: 100%;
        height: 100%;
    }   
    .Header {
        width: 100%;
        height: 100%;
    }
}